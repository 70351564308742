import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderService } from '../order.service';
import { CountDownService } from '../_services/count-down.service';
import { MqttService } from 'ngx-mqtt';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-referral-menu',
  templateUrl: './referral-menu.component.html',
  styleUrls: ['./referral-menu.component.sass']
})
export class ReferralMenuComponent {
  @Input() showBack: boolean = false;
  @Output() back = new EventEmitter<boolean>();
  storeId: string;
  hash: string;
  FilterPromot: any;


  constructor(
    private orderService: OrderService,
    private countdownService: CountDownService,
    private changeDetectorRef: ChangeDetectorRef,
    private mqttService: MqttService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.FilterPromot = JSON.parse(localStorage.getItem("referee_gets"));

  }

  goBack() {
    this.router.navigate(['store/' + this.storeId]);

  }
  
  referralItem() {
    this.router.navigate(['store/' + this.storeId + '/' + 'referral-promotion/' + this.FilterPromot.id]);
  }

  referralVoucher() {
    this.router.navigate(['store/' + this.storeId + '/referral/varification']);
  }
}
