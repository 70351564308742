import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { PaymentComponent as AddPaymentBottomSheetComponent } from './payment/payment.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrderService } from './order.service';
import { RootComponent } from './root/root.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { StoreBottomSheetComponent } from './store-bottom-sheet/store-bottom-sheet-component';
import { MenuItemMasterComponent } from './menu-item/menu-item-master/menu-item-master.component';
import { OptionsComponent } from './menu-item/options/options.component';
import { ModifierListComponent } from './menu-item/modifier-list/modifier-list.component';
import { QuantityControlComponent } from './menu-item/quantity-control/quantity-control.component';
import { InstructionComponent as MenuItemInstructionComponent } from './menu-item/instruction/instruction.component';
import { OrderSummaryMasterComponent } from './order-summary/order-summary-master/order-summary-master.component';
import { OccasionComponent } from './order-summary/occasion/occasion.component';
import { ItemListComponent } from './order-summary/item-list/item-list.component';
import { InstructionComponent as OrderSummaryInstructionComponent } from './order-summary/instruction/instruction.component';
import { PaymentComponent as OrderSummaryPaymentComponent } from './order-summary/payment/payment.component';
import { TipComponent } from './order-summary/tip/tip.component';
import { ScheduleBottomSheetComponent as OrderSummaryScheduleBottomSheetComponent } from './order-summary/schedule-bottom-sheet/schedule-bottom-sheet-component';
import { AuthInterceptor } from './_helpers/auth.interceptor';
import { PaymentMethodsBottomSheetComponent } from './order-summary/payment-methods-bottom-sheet/payment-methods-bottom-sheet-component';
import { PhoneMaskDirective } from './_helpers/phone-mask-directive';
import { MoveNextByMaxLengthDirective } from './_helpers/move-next-by-max-length';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { OffersComponent } from './offers/offers.component';
import { OfferDetailComponent } from './offers/offer-detail/offer-detail.component';
import { OfferSummaryComponent } from './offers/offer-summary/offer-summary.component';
import { OfferPreviewListComponent } from './offers/offer-preview-list/offer-preview-list.component';
import { OfferStatusComponent } from './offers/offer-status/offer-status.component';
import { PaymentStripeComponent } from './payment-stripe/payment-stripe.component';
import { MyOffersComponent } from './offers/my-offers/my-offers.component';
import { RedeemComponent } from './offers/redeem/redeem.component';
import { RedeemStatusComponent } from './offers/redeem-status/redeem-status.component';
import { RedeemHistoryComponent } from './offers/redeem-history/redeem-history.component';
import { ToolbarComponent } from './offers/toolbar/toolbar.component';
import { CountdownModule } from 'ngx-countdown';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { MasterStoreComponent } from './store-lazy/master-store/master-store.component';
import { InfoComponent } from './store-lazy/info/info.component';
import { MenuComponent } from './store-lazy/menu/menu.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { StoreService } from './_services/store.service';
import { ArrivedDialogComponent } from './arrived-dialog/arrived-dialog.component';
import { BooleanService } from './_services/boolean.service';
import { PaymentOptionService } from './_services/payment-option.service';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { OccasionDetailService } from './_services/occasion-detail.service';
import { FeedbackComponent } from './order-status/feedback/feedback.component';
import { RatingComponent } from './rating/rating.component';
import { StoreHeaderComponent } from './store-header/store-header.component';
import { GiftedComponent } from './offers/gifted/gifted.component';
import { HistoryComponent } from './history/history.component';
import { GiftedStatusComponent } from './offers/gifted-status/gifted-status.component';
import { OfferV2Component } from './offer-v2/offer-v2.component';
import { PromoCodeComponent } from './order-summary/promo-code/promo-code.component';
import { GenericAlertComponent } from './generic-alert/generic-alert.component';
import { PendingChangesGuard } from './_helpers/pending-changes-gaurd';
import { DebounceClickDirective } from './_helpers/debounce-click-directive';
import { NmiPaymentComponent } from './order-summary/nmi-payment/nmi-payment.component';
import { SurchargeLabelAlertComponent } from './surcharge-label-alert/surcharge-label-alert.component';
import { UserappLoyaltyComponent } from './userapp-loyalty/userapp-loyalty.component';
import { PointsRedeemHistoryComponent } from './userapp-loyalty/points-redeem-history/points-redeem-history.component';
import { ProgressBarColorDirective } from './_helpers/progress-bar-color.directive';
import { OrderSocketService } from './_services/order-socket.service';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { RedeemConfirmationDialogComponent } from './offers/redeem/redeem-confirmation-dialog/redeem-confirmation-dialog.component';
import { WalletComponent } from './order-summary/wallet/wallet.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { WalletCardDetailComponent } from './order-summary/wallet/wallet-card-detail/wallet-card-detail.component';
import { WalletAddCardconnectComponent } from './order-summary/wallet/wallet-add-cardconnect/wallet-add-cardconnect.component';
import { WalletAddStripeComponent } from './order-summary/wallet/wallet-add-stripe/wallet-add-stripe.component';
import { NgxStripeModule } from 'ngx-stripe';
import { MenuGroupItemsComponent } from './store-lazy/menu-group-items/menu-group-items.component';
import { MatRippleModule } from '@angular/material/core';
import { CustomTipDialogComponent } from './order-summary/tip/custom-tip-dialog/custom-tip-dialog.component';
import { MultiStoreLocationComponent } from './offers/multi-store-location/multi-store-location.component';
import { ProgressStepsComponent } from './userapp-loyalty/progress-steps/progress-steps.component';
import { OrderProgressComponent } from './order-status/order-progress/order-progress.component';
import { SendGiftCardComponent } from './order-summary/wallet/send-gift-card/send-gift-card.component';
import { OrderSuspiciousComponent } from './order-suspicious/order-suspicious.component';
import { UserActivityComponent } from './user-activity/user-activity.component';
import { CvvDialogComponent } from './order-summary/cvv-dialog/cvv-dialog.component';
import { UserLoginComponent } from './order-summary/user-login/user-login.component';
import { AlertComponent } from './alert/alert.component';
// import { LinkMqttService } from './_services/link-mqtt.service';
import { UserappReferralComponent } from './userapp-referral/userapp-referral.component';
import { UserappReferralRewardComponent } from './userapp-referral-reward/userapp-referral-reward.component';
import { CashierVarificationComponent } from './userapp-referral-reward/cashier-varification/cashier-varification.component';
import { CashierThankComponent } from './userapp-referral-reward/cashier-thank/cashier-thank.component';
import { ReferralMenuComponent } from './referral-menu/referral-menu.component';
import { ReferralPromotionComponent } from './userapp-referral/referral-promotion/referral-promotion.component';
import { ReferralPromotionValueComponent } from './userapp-referral/referral-promotion-value/referral-promotion-value.component';
import { ReferralPromotionOtpVarificationComponent } from './userapp-referral/referral-promotion-otp-varification/referral-promotion-otp-varification.component';
import { ReferralPromotionLinkComponent } from './userapp-referral/referral-promotion-link/referral-promotion-link.component';
import { ReferralVerificationComponent } from './userapp-referral-reward/referral-verification/referral-verification.component';
import { ReferralClaimComponent } from './userapp-referral-reward/referral-claim/referral-claim.component';



export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'labrador-o58365.a01.euc1.aws.hivemq.cloud',
  port: 8884,
  protocol: 'wss',
  path: '/mqtt',
  clean: true,
  username: 'labrador',
  password: 'kUbS6EyzcXYU8R',
  connectTimeout: 4000,
  reconnectPeriod: 4000,
  clientId: 'labmq-order-app-' + localStorage.getItem('mqttsession')
};
@NgModule({
  declarations: [
    AppComponent,
    AddPaymentBottomSheetComponent,
    StoreBottomSheetComponent,
    RootComponent,
    OrderStatusComponent,
    MasterStoreComponent,
    InfoComponent,
    MenuComponent,
    MenuItemMasterComponent,
    OptionsComponent,
    ModifierListComponent,
    QuantityControlComponent,
    MenuItemInstructionComponent,
    OrderSummaryMasterComponent,
    OccasionComponent,
    ItemListComponent,
    OrderSummaryInstructionComponent,
    OrderSummaryPaymentComponent,
    TipComponent,
    OrderSummaryScheduleBottomSheetComponent,
    PaymentMethodsBottomSheetComponent,
    PhoneMaskDirective,
    MoveNextByMaxLengthDirective,
    OffersComponent,
    OfferDetailComponent,
    OfferSummaryComponent,
    OfferPreviewListComponent,
    OfferStatusComponent,
    PaymentStripeComponent,
    MyOffersComponent,
    RedeemComponent,
    RedeemStatusComponent,
    RedeemHistoryComponent,
    ToolbarComponent,
    ArrivedDialogComponent,
    PaymentGatewayComponent,
    FeedbackComponent,
    RatingComponent,
    StoreHeaderComponent,
    GiftedComponent,
    HistoryComponent,
    GiftedStatusComponent,
    OfferV2Component,
    PromoCodeComponent,
    GenericAlertComponent,
    DebounceClickDirective,
    NmiPaymentComponent,
    SurchargeLabelAlertComponent,
    UserappLoyaltyComponent,
    PointsRedeemHistoryComponent,
    ProgressBarColorDirective,
    RedeemConfirmationDialogComponent,
    WalletComponent,
    WalletCardDetailComponent,
    WalletAddCardconnectComponent,
    WalletAddStripeComponent,
    MenuGroupItemsComponent,
    CustomTipDialogComponent,
    MultiStoreLocationComponent,
    ProgressStepsComponent,
    OrderProgressComponent,
    SendGiftCardComponent,
    OrderSuspiciousComponent,
    UserActivityComponent,
    AlertComponent,
    UserappReferralComponent,
    UserappReferralRewardComponent,
    CashierVarificationComponent,
    CashierThankComponent,
    CvvDialogComponent,
    ReferralMenuComponent,
    UserLoginComponent,
    ReferralPromotionComponent,
    ReferralPromotionValueComponent,
    ReferralPromotionOtpVarificationComponent,
    ReferralPromotionLinkComponent,
    ReferralVerificationComponent,
    ReferralClaimComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatInputModule,
    FormsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatCardModule,
    MatBottomSheetModule,
    MatProgressBarModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatGridListModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCy5HhP2A80AAyzuxvjLtjiznvlXIMD5o4',
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule,
    MatMenuModule,
    CountdownModule,
    CurrencyMaskModule,
    MatExpansionModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    MatFormFieldModule,
    LoadingBarHttpClientModule,
    NgxStripeModule.forRoot('pk_test_n0Zm0GzvEUvjerGeRpu1KN0B'),
    MatRippleModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    OrderService,
    FormBuilder,
    StoreService,
    BooleanService,
    PaymentOptionService,
    OccasionDetailService,
    PendingChangesGuard,
    OrderSocketService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
