import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {OrderService} from '../../order.service';
import { ActivatedRoute, Router } from '@angular/router';
import {CardDetails, OrdersWithPayment, PaymentMethods} from 'src/app/models/order';
import { WalletData } from 'src/app/models/wallet';
import { GuestPaymentDetailService } from 'src/app/_services/gpd.service';
import { Store } from 'src/app/models/store';
import { PaymentOption } from 'src/app/_services/payment-option.service';
import { get } from 'scriptjs';
import {DOCUMENT} from '@angular/common';
import {WalletAddCardconnectComponent} from './wallet-add-cardconnect/wallet-add-cardconnect.component';
import {PaymentComponent} from '../../payment/payment.component';
import {WalletAddStripeComponent} from './wallet-add-stripe/wallet-add-stripe.component';
import {OrderHelpers} from '../utils/helpers';
import { UserLoginComponent } from '../user-login/user-login.component';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var iTransact
declare var CollectJS

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.sass']
})
export class WalletComponent implements OnInit {

  storeId: string;
  phoneNumber: string;
  paymentGateway: string;
  hash: string;
  orderID = null;
  order: OrdersWithPayment;
  wallet: WalletData;

  allCards: CardDetails[] = [];
  store: Store;

  loadAPI: Promise<any>;
  payrocHandler: any;

  selectMode = false;
  forOffer  = false;

  mUseStoreCredit = false;
  mUseCash = false;
  mUseCard = false;
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<WalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private gpd: GuestPaymentDetailService,
    private dialog: MatDialog,
    private _snackBar : MatSnackBar,
    @Inject(DOCUMENT) private document: Document) {
      console.log(this.data)
      this.selectMode = this.data.selectMode;
      this.forOffer = this.data.forOffer;

      this.order = this.data.order;
      if(this.selectMode){
        this.storeId = this.data.order.store.id;
      }else{
        this.storeId = this.route.snapshot.paramMap.get('storeId');
      }

      this.hash = this.route.snapshot.paramMap.get('hash');
      this.phoneNumber = localStorage.getItem('phoneNumber');
      if (this.hash != 'null') {
        this.hashToPhoneNumber();
      } else {
        this.userLogin();
      }
      if(this.storeId){
        this.orderService.getStoreInfo(this.storeId).subscribe(store => {
          this.store = Object.assign(new Store(), store);
          this.paymentGateway = this.store.payment_gateway;
          this.loadAPI = new Promise((resolve) => {
            let node = this.loadScript(this.store.nmi_public_key);
            if (node) {
                node.onload = () => {resolve(true);};
            } else {
                resolve(true);
            }
          });
          this.store = Object.assign(new Store(), store);
          this.getCards(this.store.id, this.phoneNumber, this.store.payment_gateway);
          if(this.store && this.store.payment_gateway === 'PAYROC'){

            console.log(document.getElementById('payroc-button'));

            let options = {
              apiUsername: this.store.payroc_username,
              name: 'Please add card details below:',
              description: '',
              onToken: this.submit.bind(this),
              requireEmail: false
            }

            get("https://js.itransact.com/itransact.js", () => {
              var handler = iTransact.Checkout.init(options)
              this.payrocHandler = handler;
              this.document.getElementById('payroc-button').addEventListener('click', function (event) {
                handler.render({
                  panelButtonText: "Add Card",
                  theme: "#467adb",
                  email: 'support@labrador.ai'
                })
                event.preventDefault()
              })
            });
          }
        });
      }

      
    }

  ngOnInit(): void {
    if(this.loadAPI){
      this.loadAPI.then((flag) => {
        CollectJS.configure({
          'paymentType': 'cc',
          'primaryColor': '#1e88e5',
          'buttonText': 'Add Card',
          'instructionText': 'Enter Card Info Below',
          'theme': 'material',
          'callback': function (response) {
            console.log(response);
            response.store_id = this.storeId;
            response.phone_number = this.phoneNumber;
            response.payment_gateway = this.paymentGateway;
            this.orderService.addNewCardGateway(response).subscribe(response => {
              this._snackBar.open('Payment method added', '', { duration: 3000, verticalPosition: 'top'});
              CollectJS.closePaymentRequest();
              this.getCards(this.store.id, this.phoneNumber, this.store.payment_gateway);
            })
          }.bind(this)
        });
      });
    }
  }

  hashToPhoneNumber() {
    if (this.hash) {
      this.orderService.hashToPhoneNumber(this.hash).subscribe((data:any) => {
        localStorage.setItem("phoneNumber", data.phone_number);
        localStorage.setItem('userHash', this.hash);
      });
    }
    
  }

  userLogin() {
    let dialogRef = this.dialog.open(UserLoginComponent, {
      disableClose: true,
      width: '400px',
      data: {
        store: this.storeId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('Close !!!!');
      if (result) {
        const phone = localStorage.getItem("phoneNumber");
        this.orderService.createTempOrderWithPhone(this.storeId, phone).subscribe(data => {
          location.reload();
        })
      }
      
    });
  }

  close(){
    this.dialogRef.close();
  }

  goToHome(){
    if(this.selectMode){
      this.close();
    }else{
      window.history.back();
    }
  }

  refresh(){
    this.getCards(this.store.id, this.phoneNumber, this.store.payment_gateway);
  }

  getCards(storeId, phoneNumber, paymentGateway){
    this.orderService.getCards(storeId, phoneNumber, paymentGateway).subscribe(data => {
      console.log(data)
      this.wallet = data;
    });

    this.gpd.getAllCards(storeId, phoneNumber, paymentGateway).subscribe(data => {
      this.allCards = data.map(d => Object.assign(new CardDetails(), d));
    })
  }

  loadScript(nmiPublicKey) {
    console.log(nmiPublicKey);

    let node = undefined;
    let isFound = false;
    const scripts = document.getElementsByTagName('script')
    for (let i = 0; i < scripts.length; ++i) {
        // Check if script is already there in html
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
          isFound = true;
        }
    }

    if (!isFound) {
        const dynamicScript = 'https://secure.networkmerchants.com/token/Collect.js';
        node = document.createElement('script');
        node.src = dynamicScript;
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        node.setAttribute('data-tokenization-key', nmiPublicKey);
        document.getElementsByTagName('head')[0].appendChild(node);
        return node;
    }
    return node;
  }

  openGateway(event){
    if(this.store.payment_gateway === 'NMIPR' || this.store.payment_gateway === 'NMI'){
      this.openCollectJsLightBox(event)
    }
  }

  openCollectJsLightBox(event){
    CollectJS.configure({
      'paymentType': 'cc',
      'primaryColor': '#1e88e5',
      'buttonText': 'Add Card',
      'instructionText': 'Enter Card Info Below',
      'theme': 'material',
      'callback': function (response) {
        console.log(response);
        response.store_id = this.store.id;
        response.phone_number = this.phoneNumber;
        response.payment_gateway = this.paymentGateway;
        this.orderService.addNewCardGateway(response).subscribe(response => {
          this._snackBar.open('Payment method added', '', { duration: 3000, verticalPosition: 'top'});
          CollectJS.closePaymentRequest();
          this.getCards(this.store.id, this.phoneNumber, this.store.payment_gateway);
        })
      }.bind(this)
    });
    CollectJS.startPaymentRequest(event)
  }


  submit(data){
    console.log(data);
    let payload = {
      'store_id': this.store.id,
      'phone_number': this.phoneNumber,
      'token': data.token,
      'payment_gateway': this.store.payment_gateway
    }

    this.orderService.addNewCardGateway(payload).subscribe(response => {
      this.getCards(this.store.id, this.phoneNumber, this.store.payment_gateway);
    },
    error => {})
  }

  openCardConnect(){
    let dialogRef = this.dialog.open(WalletAddCardconnectComponent, {
      panelClass: 'full-screen-modal',
      height: 'auto',
      width: 'auto',
      data: {paymentGateway: this.paymentGateway, storeId: this.storeId, phoneNumber: this.phoneNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getCards(this.store.id, this.phoneNumber, this.store.payment_gateway);
      }
    });
  }

  gotoOrSelect(card: CardDetails){
    if(this.selectMode){
      if(!card.is_deleted){
        const paymentOption = new PaymentOption(false, true, this.mUseStoreCredit, card,
          this.wallet.store_credit)
        this.dialogRef.close(paymentOption);
      }
    }else{
      this.router.navigate(['/', 'store' , this.storeId, this.hash, 'wallet', card.token])
    }
  }

  openStripe(){
    let dialogRef = this.dialog.open(WalletAddStripeComponent, {
      panelClass: 'full-screen-modal',
      height: 'auto',
      width: 'auto',
      data: {paymentGateway: this.paymentGateway, storeId: this.store.id, phoneNumber: this.phoneNumber}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getCards(this.store.id, this.phoneNumber, this.store.payment_gateway);
      }
    });
  }

  useStoreCredit(withCash = false){
    let storeCredit = this.wallet ? this.wallet.store_credit : 0;
    const paymentOption = new PaymentOption(withCash, false, true, null,
      storeCredit)
    this.dialogRef.close(paymentOption);
  }

  useCash(){
    let storeCredit = this.wallet ? this.wallet.store_credit : 0;
    const paymentOption = new PaymentOption(true, false, false, null,
      storeCredit)
    this.dialogRef.close(paymentOption);
  }

  goToMyOffers(){
    this.router.navigate(['/', 'store' , this.storeId, this.hash, 'my-offers'])
  }

  goToSendGift(){
    this.router.navigate(['/', 'store' , this.storeId, this.hash, 'send-gift'])
  }

  isMultipleMethods(){
    if(this.wallet){
      return this.wallet.store_credit > 0 && this.wallet.store_credit < this.order?.payment.total && this.selectMode;
    }else{
      return false;
    }

  }

  getStoreCreditCardBalance(){
    return this.order.payment.total - this.wallet.store_credit;
  }

  getStoreCreditCashBalance(){
    return this.order.payment.cash_due_total - this.wallet.store_credit;
  }

  mUseCashMethod(){
    this.mUseStoreCredit = true;
    this.mUseCash = true;
    this.mUseCard = false;
  }
  mUseCardMethod(){
    this.mUseStoreCredit = true;
    this.mUseCash = false;
    this.mUseCard = true;
  }

  refreshOrder(){
    this.isLoading = true;
    this.orderService.getOrder(this.order.bot_order.order_hash).subscribe(data => {
      this.order = OrderHelpers.parseOrder(data)
      this.isLoading = false;
    });
  }

}
