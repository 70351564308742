import { Component, OnInit } from '@angular/core';
import { Store, StoreMenuItemsV2, MenuItemGroup } from 'src/app/models/store';
import { OrderService } from 'src/app/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersWithPayment, HostedMenu } from 'src/app/models/order';
import {DomSanitizer} from '@angular/platform-browser';
import { StoreService } from 'src/app/_services/store.service';
import { forkJoin } from 'rxjs';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'app-master-store',
  templateUrl: './master-store.component.html',
  styleUrls: ['./master-store.component.scss']
})
export class MasterStoreComponent implements OnInit {

  store:Store;
  storeMenu:StoreMenuItemsV2;
  storeId:string;
  orderId:string;
  orderWithPayment:OrdersWithPayment;
  menuType:string
  isLoading = true;
  hostedMenu:HostedMenu[];
  smsLink:any;
  storeCredit;
  openAll = false;
  uiSetting:any;

  constructor(
    private sanitizer:DomSanitizer,
    private route: ActivatedRoute,
    private orderService:OrderService,
    private router: Router,
    private storeService:StoreService
  ) {
    this.orderId = this.route.snapshot.paramMap.get('orderId');
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      let observables = {}
      this.store = Object.assign(new Store(), store);
      this.storeService.changeStore(this.store);
      this.uiSetting = this.store.ui_settings;
      this.smsLink = this.sanitize(`sms:${this.store.sms_number};?&body=I%27d%20like%20to%20place%20an%20Order`)

      if(store.enable_pick_and_click_ordering && store.enable_text_ordering){
        if(localStorage.getItem(this.orderId+"menuType") != null){
          this.menuType = localStorage.getItem(this.orderId+"menuType")
        }else if (this.store.default_view_text_order_menu){
          this.menuType = "text"
        }else if(store.enable_pick_and_click_ordering){
          this.menuType = "click"
        }
      }else if(store.enable_pick_and_click_ordering && !store.enable_text_ordering){
        this.menuType = "click"
      }else{
        this.menuType = "text"
      }

      if(this.store.enable_text_ordering){
        observables['hostedMenu'] = this.orderService.getHostedMenu(this.storeId)
      }
      else{
        this.isLoading = false;
      }

      if(this.store.enable_pick_and_click_ordering){
        if(!this.orderId){
          let phoneNumber = localStorage.getItem(Constants.PHONE_NUMBER_KEY)
          if(phoneNumber){
            this.orderService.createTempOrderWithPhone(this.storeId, phoneNumber).subscribe(data => {
              this.router.navigate(['order', data['order_hash'], 'store', data['url_slug']], {replaceUrl: true})
            })
          }else{
            this.orderService.createTempOrder(this.storeId).subscribe(data => {
              this.router.navigate(['order', data['order_hash'], 'store', data['url_slug']], {replaceUrl: true})
            })
          }
        }else{
          observables['storeMenu'] = this.orderService.getStoreMenuV2Lazy(this.storeId, "")
          observables['order'] = this.orderService.getOrder(this.orderId)
        }
      }

      forkJoin(observables).subscribe((data):any => {
        if(data){
          console.log(data);
          this.hostedMenu = data['hostedMenu'];
          this.storeMenu = data['storeMenu'];
          if(data['order']){
            this.orderWithPayment = Object.assign(new OrdersWithPayment(), data['order']);
            if(this.orderWithPayment.bot_order.is_submitted){
              this.router.navigate(['order', this.orderId, 'status', 'success']);
            }
          }
          this.isLoading = false;
        }
      }).add(()=>{
        if(this.orderWithPayment && this.orderWithPayment.bot_order.phone_number){
          this.orderService.getCards(this.storeId,
            this.orderWithPayment.bot_order.phone_number,
            this.orderWithPayment.store.payment_gateway).subscribe(
              data => this.storeCredit = data['store_credit'],
              error => this.storeCredit = error.error['store_credit']
            )
        }
      })
    })
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  viewOrder(){
    this.router.navigate(['order', this.orderId]);
  }

  applyFilter(filter:string){
    if(filter.length >= 3){
      this.openAll = true
      this.orderService.getStoreMenuV2Lazy(this.storeId, filter).subscribe(data => {
        this.storeMenu = data;
      })
    }else if(filter.length == 0){
      this.orderService.getStoreMenuV2Lazy(this.storeId, filter).subscribe(data => {
        this.storeMenu = data;
      })
    }
  }
  ngOnInit() {}

  onMenuTypeChange(event){
    localStorage.setItem(this.orderId+"menuType", event.value)
  }

  back(){
    console.log("adasd");

    history.go(-2)
  }
}
