import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { Store } from 'src/app/models/store';
import { OrderService } from 'src/app/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-referral-promotion',
  templateUrl: './referral-promotion.component.html',
  styleUrls: ['./referral-promotion.component.sass']
})
export class ReferralPromotionComponent {
  store;
  offerCatalogSharableLink;
  storeId: any;
  isLoading = true;
  store_id: any;
  hash: any;
  phoneNumber: any;
  FilterPromot: string;
  getId: string;
  promotionData: any;
  selectedPromotionId: any;
  FilterPromotShow: any;
  promotionId: any;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    ngNavigatorShareService: NgNavigatorShareService,
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
  }
  private ngNavigatorShareService: NgNavigatorShareService;

  ngOnInit(): void {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.store_id = localStorage.getItem('currentStore');
    this.FilterPromot = JSON.parse(localStorage.getItem("referee_gets"));
    this.promotionList();
    this.getId = this.route.snapshot.paramMap.get('id')

    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;
      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
      }
      this.isLoading = false;
    });
  }

  goBack() {
    window.history.back()
  }

  promotionList() {
    this.orderService.promotionList(this.storeId).subscribe(data => {
      this.promotionData = data.data
    this.firstPageValiCheck();
      localStorage.setItem('currentStore', data.store_id)
      if (this.promotionData.length === 1) {
        this.selectedPromotionId = this.promotionData[0].id;
      }
      this.FilterPromotShow = this.promotionData.find(promotion => promotion.id == this.getId);
      localStorage.setItem('referee_gets', JSON.stringify(this.FilterPromotShow));

    });

  }

  firstPageValiCheck() {
    this.store_id = localStorage.getItem('currentStore')
    let keySend = {
      "store_id": this.store_id,
      "referral_promotion_id": this.getId,
      "phone_number": this.phoneNumber,
      "referee_name": ''
    }

    this.orderService.refereeRegistration(keySend).subscribe(data => {
      if (data.is_verified == "true") {
        this.router.navigate(['store/' + this.storeId + '/referral-promotion-code']);
      }
    })
  }


  nextPage() {
    this.router.navigate(['store/' + this.storeId + '/referral-promotion-value']);
  }
}
